import RedacaoCorrigida from "@/interfaces/RedacaoCorrigida";
import store from "@/store";

const RedacaoCorrigidaColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    sortable: false,
  },
  {
    name: "nome",
    align: "left",
    label: "Código do Aluno",
    field: (row: RedacaoCorrigida) => {
      console.log(store.getters.userPerfil);
      const created_at = row.aluno.created_at;
      const name =
        store.getters.userPerfil == "administrador" ||
        store.getters.userPerfil == "assistente"
          ? `(${row.aluno.nome})`
          : "";
      return `${new Date(created_at).getUTCFullYear()}${String(
        new Date(created_at).getUTCMonth() + 1
      ).padStart(2, "0")}${String(new Date(created_at).getUTCDate()).padStart(
        2,
        "0"
      )}${String(new Date(created_at).getUTCHours()).padStart(2, "0")}${String(
        new Date(created_at).getUTCMinutes()
      ).padStart(2, "0")}${String(
        new Date(created_at).getUTCSeconds()
      ).padStart(2, "0")} ${name}`;
    },
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data de Submissão",
    field: (row: RedacaoCorrigida) => row.created_at,
    sortable: true,
  },
  {
    name: "professor",
    align: "left",
    label: "Corrigido por",
    field: (row: RedacaoCorrigida) =>
      row?.professor ? row.professor.nome : "-",
    sortable: true,
  },
  {
    name: "updated_at",
    align: "center",
    label: "Data de Correção",
    field: (row: RedacaoCorrigida) => (row.status == "C" ? row.updated_at : ""),
    sortable: true,
  },
];

export default RedacaoCorrigidaColumn;
